import type { Material as APIMaterial } from "app/types/API/Material";
import type { Traceability } from "app/types/API/Traceability";
import type { Material, MaterialSummary } from "app/types/internal/Materials";
import MaterialService from "app/services/MaterialService";
import * as _ from 'lodash';
import TraceabilityService from "app/services/TraceabilityService";
import { mapApiMaterialToMaterial, mapApiMaterialToMaterialSummary } from "app/utils/mappers";
import { Quality } from "app/types/API/Quality";
import { arg_getRelatedMaterials } from "app/types/internal/Arguments";
import IMaterialController from "../interfaces/IMaterialController";

class MaterialController implements IMaterialController {

    constructor(){
    }

    getMaterialSummary = async (barcode_id: string): Promise<MaterialSummary | undefined> => {
        var material: APIMaterial | undefined; 
        try{
            material = await MaterialService.getMaterial(barcode_id);
        } catch(e) {
            console.log("Error:",e);
        }
        if (material){
            var materialSummary: MaterialSummary = mapApiMaterialToMaterialSummary(material);
            return materialSummary;
        }
        return undefined;
    }
    
    getMaterialDetails = async (barcode_id: string): Promise<Material | null> => {
        var material_api: APIMaterial | undefined; 
        var traceabilityData: Traceability | undefined;
        var qualityData: Quality[]
        try{
            material_api = await MaterialService.getMaterial(barcode_id);
            traceabilityData = await TraceabilityService.getTraceabilityData(barcode_id);
            qualityData = await MaterialService.getQualityData(barcode_id);

        } catch (e) {
            if (e.response && e.response.status === 404) {
                console.log("Material not found:", e);
                return null;
            } else {
                console.error("Error:", e);
                return null;
            }
        }
        if (material_api){
            var material: Material = mapApiMaterialToMaterial(material_api, traceabilityData, qualityData);
            return material;
        }
    
        return null;
    }
    
    getRelatedMaterials = async (args:arg_getRelatedMaterials): Promise<MaterialSummary[]> => {
        var related_materials: APIMaterial[]; 
        try{
            related_materials = await MaterialService.getRelatedMaterials(args);
        } catch(e) {
            console.log("Error:", e);
        }
        if (related_materials){
            let materialSummaries: MaterialSummary[] = related_materials.map((m) => {
                return mapApiMaterialToMaterialSummary(m);
            });
            return materialSummaries;
        }
        return [];
    }
}

let APIMaterialController;
export default APIMaterialController = new MaterialController();

