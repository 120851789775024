import React from 'react';
import ReactDOM from 'react-dom/client';
import "app/view/styles/layout.css"
import "app/view/styles/root.css"
import App from 'app/view/App';
import ErrorPage from 'app/view/pages/errorPage/ErrorPage'
import MaterialPage, {action as materialAction} from 'app/view/pages/materialPage/MaterialPage';
import LandingPage, {action as landingAction} from 'app/view/pages/landingPage/LandingPage';
import { StoreProvider } from 'app/state/StoreProvider';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App />}
      errorElement={<ErrorPage/>}
    >
      <Route errorElement={<ErrorPage/>}>
        <Route
          index
          element={<LandingPage/>}
          action={({request}) => landingAction(request)}
        />
        <Route
          path="/material/:barcode_id"
          element={<MaterialPage/>}
          loader={({params}) => params.barcode_id}
          action={({request}) => materialAction(request)}
        />
        <Route
            path="/material"
            element={<MaterialPage/>}
            loader={({params}) => null}
            action={({request}) => materialAction(request)}
        />
      </Route>
    </Route>
  )
)


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  
  <React.StrictMode>
    <StoreProvider>
      <RouterProvider router={router}/>
    </StoreProvider>
  </React.StrictMode>
);
