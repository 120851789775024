import { IxIcon } from '@siemens/ix-react';
import classNames from 'classnames/bind';
import styles from './Dropdown.module.css'

interface Props {
    text: string;
    onClick?: () => void;
    selected?: boolean 
}

const consoleLog = () => {
    console.log("clicked")
}

const cx = classNames.bind(styles)

const DropItemCheckBox = ({text, onClick=consoleLog, selected=false}: Props) => {
    return (
        <div 
            onClick={onClick} 
            className={cx({
                dropdown_item: true,
                dropdown_item_selected: selected,
            })}
        >
            <div className={styles.checkbox}>
                {selected && 
                    <IxIcon 
                        name="single-check" 
                        size='16'
                    ></IxIcon>
                }
            </div>
            {text}
        </div>
    )
}

export default DropItemCheckBox;