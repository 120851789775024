import { useState } from "react";
import LargeSearchBar from "app/view/components/searchBar/LargeSearchBar";
import styles from "./LandingPage.module.css"
import { redirect, useSubmit } from "react-router-dom";

export const action = async (request: Request) => {
    let data = await request.formData()
    return redirect(`/material/${data.get("barcode")}`)
}

const LandingPage = () => {

    const [rootSearchTerm, setRootSearchTerm] = useState<string>("");

    let submit = useSubmit();

    const onMainSearch = (term: string) => {
        submit({barcode: term}, {method: "post"});
    }
    return (
        <div className={styles.container}>
            <LargeSearchBar
                onSearch={(term) => onMainSearch(term)}
                set_content={rootSearchTerm}
                placeholder="Serial number"
                title="Serial number"
            />
        </div>
    );
}

export default LandingPage