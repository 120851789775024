import { observer } from "mobx-react-lite";
import Appbar from "app/view/components/appbar/Appbar";
import Header from "app/view/components/header/Header";
import { Outlet } from "react-router-dom";
import { useStore } from "app/state/StoreProvider";
import AuthContainer from "./containers/authContainer/AuthContainer";
import { ToastContainer } from "react-toastify";

const App = observer(() => {
    const {
        uiState:{
            displayAuth
        }
    } = useStore();

    return (
        <section className="layout">
            <nav><Appbar/></nav>
            <main>
            {displayAuth &&
                <AuthContainer/>
            }
            <ToastContainer/>
            <Outlet/>
            </main>
        </section>
    );
})

export default App;
