import AccordionItem from './AccordionItem';
import { useState } from 'react';

interface Props {
    accordionElements: any,
    styles: any
}

const Accordion = ({ accordionElements, styles }: Props) => {
    const [clicked, setClicked] = useState([-1]);

    return (
        <div>
            {Object.keys(accordionElements)?.map((key: string, index: number) => (
                <div key={key + "__" + index} className={`${styles.content_element_quality_key}`}>
                    <AccordionItem elements={accordionElements[key]} equipmentKey={key} clicked={clicked} setClicked={setClicked} accordionIndex={index} styles={styles} />
                </div>
            ))}
        </div>
    )
}

export default Accordion;