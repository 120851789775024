import geneology from "app/controllers/demo/files/geneology.json";
import type { Material, MaterialSummary } from "app/types/internal/Materials";
import type { Material as DummyMaterial } from "app/types/Dummy/Material";
import * as _ from 'lodash';
import { mapDummyMaterialToMaterial, mapDummyMaterialToMaterialSummary, mapMaterialToMaterialSummary } from "app/utils/mappers";
import { arg_getRelatedMaterials } from "app/types/internal/Arguments";
import IMaterialController from "../interfaces/IMaterialController";
import DemoProcessController from "app/controllers/demo/ProcessController";
import { MeasurementName } from "app/types/internal/Process";

class MaterialController implements IMaterialController {
    
    complete_materials: Material[]; //Does not include children and parents. These are added in getMaterialDetails.
    constructor(){
        this.complete_materials = this._readGenealogy();
    }
    
    private _readGenealogy = (): Material[] => {
        let materials = geneology.materials.map((m) => {
            return mapDummyMaterialToMaterial(m);
        });
        // Need to complete the parents, children and measurements, mapper does not handle this.
        materials.forEach( (material: Material) => {
            let dummy_equivalent = geneology.materials.find(m => m.barcode_id === material.barcode_id);
            material.parents = dummy_equivalent.parent_ids.map(p_cid => {
                let dummy_parent: DummyMaterial = geneology.materials.find(m => m.container_id === p_cid);
                let parent_summary: MaterialSummary = mapDummyMaterialToMaterialSummary(dummy_parent);
                return parent_summary;
            });
            material.children = dummy_equivalent.child_ids.map(p_cid => {
                let dummy_child: DummyMaterial = geneology.materials.find(m => m.container_id === p_cid);
                let child_summary: MaterialSummary = mapDummyMaterialToMaterialSummary(dummy_child);
                return child_summary;
            });
            material.measurements = dummy_equivalent.process_tags.map(t => {
                let measurement: MeasurementName = {
                    tagName: t.name,
                    resourceName: t.name,
                    assetId: t.name,
                    resourceId: t.name
                };
                return measurement;
            })
        });
        return materials;
    }
    
    private getSuccessiveMaterials = async (root_material: Material): Promise<MaterialSummary[]> => {
        let child_q: Material[] = [];
        root_material.children.forEach(c => { 
            let child = this.complete_materials.find(m => m.barcode_id === c.barcode_id);
            child_q.push(child);
        });
        let child_summaries: MaterialSummary[] = [];
        while (child_q.length > 0){
            let child: Material | undefined = child_q.pop();
            if (child === undefined){
                break;
            }
            child.children.forEach(c => { 
                let child = this.complete_materials.find(m => m.barcode_id === c.barcode_id);
                child_q.push(child);
            });
            let child_summary: MaterialSummary = mapMaterialToMaterialSummary(child);
            child_summaries.push(child_summary);
        }
        let children = _.uniqWith(child_summaries, _.isEqual);
        return Promise.resolve(children);
    }

    private getPrecedingMaterials = async (root_material: Material): Promise<MaterialSummary[]> => {
        let parent_q: Material[] = [];
        root_material?.parents.forEach(c => { 
            let parent = this.complete_materials.find(m => m.barcode_id === c.barcode_id);
            parent_q.push(parent);
        });
        let parent_summaries: MaterialSummary[] = [];
        while (parent_q.length > 0){
            let parent: Material | undefined = parent_q.pop();
            if (parent === undefined){
                break;
            }
            parent.parents.forEach(c => { 
                let parent = this.complete_materials.find(m => m.barcode_id === c.barcode_id);
                parent_q.push(parent);
            });
            let parent_summary: MaterialSummary = mapMaterialToMaterialSummary(parent);
            parent_summaries.push(parent_summary);
        }
        let parents = _.uniqWith(parent_summaries, _.isEqual);
        return Promise.resolve(parents);
    }

    getMaterialDetails = async (barcode_id: string): Promise<Material | null> => {
        let material = this.complete_materials.find((mat) => mat.barcode_id === barcode_id)
        if (material === undefined){
            return Promise.resolve(null);
        }
        return Promise.resolve(material);
    }

    getMaterialSummary = (barcode_id: string): Promise<MaterialSummary | undefined> => {
        let material = this.complete_materials.find((mat) => mat.barcode_id === barcode_id)
        if (material === undefined){
            return Promise.resolve(undefined);
        }
        let summary: MaterialSummary = mapMaterialToMaterialSummary(material);
        return Promise.resolve(summary);
    }

    getRelatedMaterials = async (args:arg_getRelatedMaterials): Promise<MaterialSummary[]> => {
        let root_material: Material | undefined = await this.getMaterialDetails(args.barcode_id);
        if (!root_material){
            console.log(`A material with barcode id=${args.barcode_id} does not exist.`)
            return Promise.resolve([]);
        }
        let precedingMaterials = [];
        let successiveMaterials = [];
        switch(args.traceabilityFilter){
            case 0:
                precedingMaterials = await this.getPrecedingMaterials(root_material);
                successiveMaterials = await this.getSuccessiveMaterials(root_material);
                break;
            case 1:
                precedingMaterials = await this.getPrecedingMaterials(root_material);
                break;
            case 2:
                successiveMaterials = await this.getSuccessiveMaterials(root_material);
                break;
        }
        let related_materials: MaterialSummary[] = [...precedingMaterials, ...successiveMaterials];
        return related_materials
    }

    getDummyMaterial = async (barcode_id: string): Promise<DummyMaterial | null> => {
        let material = geneology.materials.find((mat) => mat.barcode_id === barcode_id)
        if (material === undefined){
            return Promise.resolve(null);
        }
        return Promise.resolve(material);
    }
}

let DemoMaterialController;
export default DemoMaterialController = new MaterialController();


