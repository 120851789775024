import { observer } from "mobx-react-lite"
import { useStore } from "app/state/StoreProvider"
import TraceabilityFilter from "app/view/components/traceabilityFilter/TraceabilityFilter"
import styles from './FilterContainer.module.css'
import { useState } from "react"
import { IxDropdownButton, IxDropdownItem, IxExpandingSearch, IxIcon } from "@siemens/ix-react"
import ExportController from "app/controllers/api/ExportController"
import { IxExpandingSearchCustomEvent } from "@siemens/ix"

const FilterContainer = observer(() => {

    const [showFilters, setShowFilters] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>("");

    const { materialStore: {
        assignSearchFilter,
        assignTraceabilityFilter,
        allMaterials,
        typeFilter,
        assignTypeFilter,
        unassignTypeFilter,
        resetFilters,
        traceabilityFilter,
        tableMaterials,
    }} = useStore()

    const onChangeUpdateTerm = (event: IxExpandingSearchCustomEvent<string>) => {
        setSearchTerm(event.target.value);
        if (event.target.value === ""){
            assignSearchFilter("");
        }
    }
    
    const onKeyUp = (event: React.KeyboardEvent<HTMLIxExpandingSearchElement>) => {
        if (event.key === "Enter"){
            assignSearchFilter(searchTerm);
        }
    }

    return (
        <div className={styles.filter_wrapper}>
            <div className={styles.filter_controls}>
                <div 
                    className={styles.filter_control_container}
                    onClick={() => ExportController.downloadTable(tableMaterials)}
                >
                    <div className={styles.filter_control_icon}>
                        <IxIcon name="download"/>
                    </div>
                    <span>Export Table</span>
                </div>
                <div 
                    className={styles.filter_control_container}
                    onClick={() => setShowFilters(p => !p)} 
                >
                    <div className={styles.filter_control_icon}>
                        <IxIcon name="filter"/>
                    </div>
                    <span>{showFilters ? "Hide filters" : "Filter data"}</span>
                </div>
                { showFilters &&
                    <div className={styles.filter_control_container} onClick={() => resetFilters()}>
                        <div className={styles.filter_control_icon}>
                            <IxIcon  name="reset"/>
                        </div>
                        <span>Reset</span>
                    </div>
                }
            </div>

            { showFilters &&
                <div className={styles.filter_container}>
                    <TraceabilityFilter onCheck={assignTraceabilityFilter} state={traceabilityFilter}/>

                    <IxDropdownButton
                        label={ typeFilter !== "" && allMaterials !== null ? allMaterials.find(m => m.material_name === typeFilter).material_name :  "Material Type"}
                        variant="primary"
                        ghost
                        icon="checkboxes"
                    >
                        <IxDropdownItem 
                            label="Clear filter"
                            onClick={() => {
                                unassignTypeFilter(); 
                            }}

                        >
                        </IxDropdownItem>
                        {allMaterials && Array.from(new Map(allMaterials.filter(m => m.material_name != null).map((k) => 
                            [k["material_name"], k]))
                            .values()).map(m => 
                                <IxDropdownItem
                                label={m.material_name} 
                                onClick={() => {
                                    assignTypeFilter(m.material_name);
                                }} 
                                checked={typeFilter===m.material_name}
                                style={{width: "fit-content(100%)"}}
                                />
                                )}
                    </IxDropdownButton>

                    <IxExpandingSearch 
                        placeholder="Search..."
                        onValueChange={onChangeUpdateTerm}
                        onKeyUp={onKeyUp}
                        value={searchTerm}
                    ></IxExpandingSearch>
                </div>
            }
        </div>
    )
})

export default FilterContainer