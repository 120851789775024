import Cookies from 'js-cookie';


const AuthService = (() => {
    const HOST = process.env.REACT_APP_API_URL; // Update the base URL

    const getToken = async (username: string, password: string): Promise<string> => {
        try {
            const response = await fetch(`${HOST}/stapi/Auth/login`, {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
                },
                body: JSON.stringify({
                    username: username,
                    password: password,
                }),
            });
            
            if (!response.ok) {
                console.log("Could not get login token, verify user/pass")
                return "";
            }
            
            const token = await response.text(); 
            return token;
        } catch (error) {
            
            return Promise.reject(error);
        }
    };

    return { getToken };
})();

export default AuthService;
