import { Material } from "app/types/internal/Materials";
import { Material as DummyMaterial } from "app/types/Dummy/Material"
import { MeasurementData, MeasurementName, TimeSeries } from "app/types/internal/Process";
import DemoMaterialController from "app/controllers/demo/MaterialController";
import IProcessController from "../interfaces/IProcessController";

class ProcessController implements IProcessController {
    constructor() {
    }

    private getTimeSeries = async (period: {"start": string, "stop": string}, file: string): Promise<TimeSeries[]> => {
        let time_series: TimeSeries[] = await import(`./files/${file}.json`).then(res => Object.values(res));
        let filter_ts = time_series.filter((dp:TimeSeries) => new Date(dp.time) > new Date(period.start) && new Date(dp.time) < new Date(period.stop));
        return filter_ts;
    }

    async getMeasurementNames(barcode_id: string): Promise<MeasurementName[]> {
        let m: Material = await DemoMaterialController.getMaterialDetails(barcode_id);
        return m.measurements;
    }

    async getMeasurementData(barcode_id: string, measurement: MeasurementName): Promise<MeasurementData> {
        let m: DummyMaterial = await DemoMaterialController.getDummyMaterial(barcode_id);
        let m_tag = m.process_tags.find(t => t.name === measurement.tagName);
        let start_name = m_tag.start_name;
        let stop_name = m_tag.stop_name;
        let start_trans = m.transactions.find(t => t.name === start_name);
        let stop_trans = m.transactions.find(t => t.name === stop_name);
        let timeSeries = await this.getTimeSeries({"start": start_trans.datetime_iso, "stop": stop_trans.datetime_iso}, m_tag.file)
        let measurementData: MeasurementData = {
            tagName: measurement.tagName,
            dataType: m.process_tags.find(t => t.name === measurement.tagName).type,
            uom: m.process_tags.find(t => t.name === measurement.tagName).uom,
            transactionStart: {
                name: start_trans.name,
                time: start_trans.datetime_iso
            },
            transactionEnd: {
                name: stop_trans.name,
                time: stop_trans.datetime_iso
            },
            timeSeries: timeSeries 
        };
        return measurementData;
    }
}

let DemoProcessController;
export default DemoProcessController = new ProcessController();

