import { observer } from "mobx-react-lite";
import { Material } from "app/types/internal/Materials";
import styles from './Headline.module.css';
import { IxSpinner } from "@siemens/ix-react";

interface Props {
    material: Material | null;
    onClick?: () => void;
}

const default_onClick = () => {
    console.log("clicked headline")
}

const Headline = observer(({material, onClick=default_onClick}: Props) => {

    return (
        <div className={styles.headline_container}>
        {material !== null ?
            <>
            <div className={styles.firstrow}>
                <div 
                    className={styles.name}
                    onClick={() => onClick()}
                >
                    {material.material_name ? material.material_name : "N/A"}
                </div>
                <div className={styles.barcode}>{material.barcode_id ? material.barcode_id : "N/A"}</div>
            </div>
            <div className={styles.secondrow}>
                <div className={styles.materialcode}>{material.material_id ? material.material_id : "N/A"}</div>
            </div>
            </>
        :
            <IxSpinner></IxSpinner>
        }
        </div>
    )
})

export default Headline