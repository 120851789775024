import accordionStyles from './Accordion.module.css';
interface Props {
    itemContent: any,
    categoryKey: number
    styles: any,
    active: boolean,
}

const AccordionItemContent = ({ itemContent, categoryKey, styles, active }: Props) => {
    return (
        <div className={`${accordionStyles.content_wrapper} ${active ? accordionStyles.open : ""}`}>
            <div className={`${styles.section_title}`}>
                {categoryKey}
            </div>
            <div key={"properties_" + categoryKey} className={styles.content_element_properties}>
                {itemContent.map((item: any, index: number) => (
                    <div key={index + "__" + categoryKey + "_" + itemContent.field_name} className={`${styles.content_element_properties_row}`}>
                        <div className={styles.content_element_property_key}>{item.field_name}</div>
                        <div>
                            <span className={styles.content_element_property_value}>{item.value ? item.value : "N/A"}</span>
                            <span className={styles.content_element_property_uom}> {item.uom}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default AccordionItemContent;