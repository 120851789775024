import { Traceability } from "app/types/API/Traceability";
import { authHeader } from "app/utils/headers";
import Cookies from 'js-cookie';

const TraceabilityService = (() => {
    const HOST = process.env.REACT_APP_API_URL;
   
    const getTraceabilityData = async (barcode_id: string): Promise<Traceability> => {
        var request = fetch(`${HOST}/Material/${barcode_id}/Traceability`, {
            method: "GET",
            mode: "cors",    
            headers: {
                 // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            //credentials: 'include',
        })
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<Traceability>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;
    }

    return {
        getTraceabilityData,
    };
})();

export default TraceabilityService;