import { useStore } from 'app/state/StoreProvider';
import { MaterialSummary } from 'app/types/internal/Materials';
import styles from './TraceabilityTab.module.css'
import { IxSpinner } from '@siemens/ix-react';

const TraceabilityTab = () => {

    const {
        materialStore: {
            selectedMaterial,
            assignSelectedMaterial
        }
    } = useStore()

    const materialTransactions = (): { "name": string, "time": any }[] => {
        if (selectedMaterial) {
            let formatted_transactions = selectedMaterial.transactions.map(t => {
                let name = t.name;
                let time = new Date(t.datetime_iso).toLocaleString();
                return { "name": name, "time": time };
            })
            return formatted_transactions;
        }
        return []
    }

    const materialParents = (): MaterialSummary[] => {
        if (selectedMaterial) {
            return selectedMaterial.parents;
        }
        return []
    }

    const materialChildren = (): MaterialSummary[] => {
        if (selectedMaterial) {
            return selectedMaterial.children;
        }
        return []
    }

    return (
        <>
        { selectedMaterial ?
            <>
            {/* General */}
            <div className = { styles.section_content } >
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Serial Number
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.barcode_id ? selectedMaterial.barcode_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Material ID
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.material_id ? selectedMaterial.material_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Container ID
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.container_id ? selectedMaterial.container_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Workorder
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.workorder_id ? selectedMaterial.workorder_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Registration Date
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.registration_datetime_iso ? new Date(selectedMaterial.registration_datetime_iso).toLocaleString() : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Batch
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.batch_id ? selectedMaterial.batch_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Recipe
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.recipe_id ? selectedMaterial.recipe_id : "N/A"}
                    </div>
                </div>
                <div className={styles.content_element_properties_row}>
                    <div className={styles.content_element_property_key}>
                        Transportation Unit
                    </div>
                    <div className={styles.content_element_property_value}>
                        {selectedMaterial.carrier_id ? selectedMaterial.carrier_id : "N/A"}
                    </div>
                </div>
            </div >
            {/* Transactions */ }
            <div className = { styles.section } >
                <div className={styles.section_title}>
                    Transactions
                </div>
                    <div className={styles.section_content}>
                        {materialTransactions().map(t => (
                            <div className={styles.content_element_properties_row}>
                                <div className={styles.content_element_property_key}>{t.name}</div>
                                <div className={styles.content_element_property_value}>{t.time}</div>
                            </div>
                        ))}
                    </div>
            </div >
            {/* Relations */ }
            <div className = { styles.section } >
                <div className={styles.section_title}>
                    Relations
                </div>
                <div className={styles.section_content}>
                    <div className={styles.content_element_properties_row}>
                        <div className={styles.content_element_property_key}>Preceding</div>
                        {materialParents().map(p => (
                            <div className={styles.content_element_property_value}>
                                {p.barcode_id}
                                <div 
                                    className={styles.content_element_property_ellipsis}
                                    onClick={() => assignSelectedMaterial(p.barcode_id)}
                                    >
                                    ({p.material_name ? p.material_name : "N/A"})
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.content_element_properties_row}>
                        <div className={styles.content_element_property_key}>Successive</div>
                        {materialChildren().map(c => (
                            <div className={styles.content_element_property_value}>
                                {c.barcode_id}
                                <div 
                                    className={styles.content_element_property_ellipsis}
                                    onClick={() => assignSelectedMaterial(c.barcode_id)}
                                    >
                                    ({c.material_name ? c.material_name : "N/A"})
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div >
            </>          
        :
            <div className={styles.spinner_container}>
                <IxSpinner></IxSpinner>
            </div>
        }
        </>
    )
}

export default TraceabilityTab;