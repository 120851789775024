import { MeasurementData, MeasurementName, TimeSeries } from "app/types/internal/Process";
import IProcessController from "../interfaces/IProcessController";
import { EquipmentCollection, ProcessTag } from "app/types/API/Process";
import ProcessService from "app/services/ProcessService";
import { mapApiProcessTagToMeasurementData } from "app/utils/mappers";

class ProcessController implements IProcessController {
    async getMeasurementNames(barcode_id: string): Promise<MeasurementName[]> {
        const equipment: EquipmentCollection = await ProcessService.getProcess(barcode_id);
        let measurements: MeasurementName[] = [];
        for (const equip in equipment){
            let variable_list = equipment[equip].variables;
            let asset_id = equipment[equip].asset_id;
            let resource_name = equipment[equip].resource_name;
            let resource_Id  =   equip;

            variable_list.forEach(tag => {
                    let name: MeasurementName = {
                        tagName: tag,
                        resourceName: resource_name,
                        assetId: asset_id,
                        resourceId: resource_Id
                    };
                    measurements.push(name);     
            });
        }
        return measurements;
    }

    async getMeasurementData(barcode_id: string, measurement: MeasurementName): Promise<MeasurementData> {
        const processTag: ProcessTag = await ProcessService.getProcessTag(barcode_id, measurement.tagName, measurement.assetId, measurement.resourceId);
        const data: MeasurementData = mapApiProcessTagToMeasurementData(processTag);
        return data;
    }
}

let APIProcessController;
export default APIProcessController = new ProcessController();
