import type { Material as APIMaterial } from "app/types/API/Material";
import type { Material as DummyMaterial } from "app/types/Dummy/Material";
import { Traceability } from "app/types/API/Traceability";
import type { Material, MaterialSummary } from "app/types/internal/Materials";
import { Quality } from "app/types/API/Quality";
import { arg_getRelatedMaterials } from "app/types/internal/Arguments";
import { arg_getRelatedMaterials  as api_getRelatedMaterials} from "app/types/API/Arguments";
import { MeasurementData, TimeSeries } from "app/types/internal/Process";
import { ProcessTag } from "app/types/API/Process";

/**
 * API mappings
 */

const mapApiProcessTagToMeasurementData = (processTag: ProcessTag): MeasurementData => {
    let measurement: MeasurementData = {
        tagName: processTag.processTag.tagName,
        dataType: processTag.processTag.dataType,
        uom: processTag.processTag.uom,
        transactionStart: {
            name: processTag.processTag.transactionStart.name,
            time: processTag.processTag.transactionStart.time
        },
        transactionEnd: {
            name: processTag.processTag.transactionEnd.name,
            time: processTag.processTag.transactionEnd.time
        },
        timeSeries: processTag.timeSeries.map(ts => {
            let internal_ts: TimeSeries = {
                value: ts.value,
                time: ts.time
            }
            return internal_ts;
        })
    };
    return measurement;
}

const mapApiMaterialToMaterialSummary = (apiMaterial: APIMaterial): MaterialSummary => {
    var materialSummary: MaterialSummary = {
        container_id: null,
        barcode_id: apiMaterial.barcodeId,
        material_id: apiMaterial.materialType,
        workorder_id: apiMaterial.workOrder,
        material_name: apiMaterial.materialName,
        registration_datetime_iso: apiMaterial.registration,
    }
    return materialSummary;
}
const mapApiMaterialToMaterial = (apiMaterial: APIMaterial, apiTraceability: Traceability, apiQualityList: Quality[]): Material => {
    var material: Material = {
        container_id: apiTraceability.identifiers.containerId,
        barcode_id: apiMaterial.barcodeId,
        material_id: apiMaterial.materialType,
        recipe_id: apiTraceability.identifiers.recipeId,
        workorder_id: apiMaterial.workOrder,
        batch_id: apiTraceability.identifiers.batchId,
        material_name: apiMaterial.materialName,
        registration_datetime_iso: apiMaterial.registration,
        children: apiTraceability.successiveMaterials.map((m) => {return mapApiMaterialToMaterialSummary(m)}),
        parents: apiTraceability.precedingMaterials.map((m) => {return mapApiMaterialToMaterialSummary(m)}),
        equipment_ids: [],
        measurements: [],
        transactions: apiTraceability.transactions.map((t) => {
            return {"name": t.name, "datetime_iso": t.time};
        }),
        quality: apiQualityList.map((q) => {
            return {
                "category": q.category,
                "sub_category": q.subCategory,
                "field_name": q.fieldName,
                "value": q.value,
                "type": q.dataType,
                "uom": q.uom
            };
        }),
        carrier_id: apiTraceability.identifiers.carrierId
    }
    return material;
}

const mapQueryArgsRelatedMaterials = (args: arg_getRelatedMaterials): api_getRelatedMaterials => {
    
    let fieldSort;
    switch(args.fieldSort){
        case 'barcode_id':
            fieldSort = "1";
            break;
        case 'material_name':
            fieldSort = "2";
            break;
        case 'workorder_id':
            fieldSort = "3";
            break;
        case 'registration_datetime_iso':
            fieldSort = "4";
            break;
        default:
            fieldSort = "0";
            break;
    }

    let traceabilityFilter;
    switch(args.traceabilityFilter){
        case 0:
            traceabilityFilter = "0";
            break;
        case 1:
            traceabilityFilter = "2";
            break;
        case 2:
            traceabilityFilter = "1";
            break;
        default:
            traceabilityFilter = "0";
            break;
    }

    let directionSort;
    switch(args.directionSort){
        case -1:
            directionSort = "0";
            break;
        case 1:
            directionSort = "1";
            break;
        default:
            directionSort = "1";
            break;
    }

    let api_args: api_getRelatedMaterials = {
        traceabilityFilter: traceabilityFilter,
        searchFilter: args.searchFilter ? args.searchFilter : "",
        typeFilter: args.typeFilter ? args.typeFilter : "",
        fieldSort: fieldSort,
        directionSort: directionSort
    }
    return api_args;
}

/**
 * Dummy data mappings
 */

function mapDummyMaterialToMaterial(dummyMaterial: DummyMaterial): Material {
    // Map the values from the dummyMaterial to the Material interface
    var material: Material = {
        container_id: dummyMaterial.container_id,
        barcode_id: dummyMaterial.barcode_id,
        material_id: dummyMaterial.material_id,
        recipe_id: dummyMaterial.recipe_id,
        workorder_id: dummyMaterial.workorder_id,
        batch_id: dummyMaterial.batch_id,
        carrier_id: dummyMaterial.carrier_id,
        material_name: dummyMaterial.material_name,
        registration_datetime_iso: dummyMaterial.registration_datetime_iso,
        children: null, // You didn't provide children in the input object
        parents: null, // You didn't provide parents in the input object
        equipment_ids: dummyMaterial.equipment_ids,
        measurements: dummyMaterial.process_tags.map((tag) => ({
            tagName: tag.name,
            resourceName: tag.name,
            assetId: tag.name,
            resourceId: tag.name
        })),
        transactions: dummyMaterial.transactions.map((transaction) => ({
            name: transaction.name,
            datetime_iso: transaction.datetime_iso,
        })),
        quality: dummyMaterial.quality.map((quality) => ({
            category: quality.equipment,
            sub_category: quality.category,
            field_name: quality.field_name,
            value: quality.value.toString(),
            type: quality.type,
            uom: quality.uom,
        })),
    };
    return material
}

function mapDummyMaterialToMaterialSummary(dummyMaterial: DummyMaterial): MaterialSummary {
    // Map the values from the dummyMaterial to the Material interface
    var material: MaterialSummary = {
        container_id: dummyMaterial.container_id,
        barcode_id: dummyMaterial.barcode_id,
        material_id: dummyMaterial.material_id,
        workorder_id: dummyMaterial.workorder_id,
        material_name: dummyMaterial.material_name,
        registration_datetime_iso: dummyMaterial.registration_datetime_iso
    };
    return material
}

/**
 * Internal
 */

const mapMaterialToMaterialSummary = (material: Material): MaterialSummary => {
    let mat_summary: MaterialSummary = {
        container_id: material.container_id,
        barcode_id: material.barcode_id,
        material_id: material.material_id,
        workorder_id: material.workorder_id,
        material_name: material.material_name,
        registration_datetime_iso: material.registration_datetime_iso,
    }
    return mat_summary 
}
export {
    mapApiProcessTagToMeasurementData,
    mapApiMaterialToMaterialSummary,
    mapApiMaterialToMaterial,
    mapDummyMaterialToMaterial,
    mapDummyMaterialToMaterialSummary,
    mapMaterialToMaterialSummary,
    mapQueryArgsRelatedMaterials
}