import { IxIcon } from '@siemens/ix-react';
import styles from './TableCells.module.css';

interface Props {
    title: string;
    onClick: () => void;
    showSortDirection: boolean;
    sortDirection: "asc" | "desc"
}

const HeadingCell = ({title, onClick, showSortDirection, sortDirection}: Props) =>{

    return(
        <th className={styles.table_header_cell}>
            <div className={styles.table_header_cell_content} onClick={onClick}>
                <span className={styles.table_header_cell_title}>
                    {title}
                </span> 
                <div className={styles.table_header_cell_arrows}>
                    { showSortDirection &&
                    <>
                        { sortDirection === "asc" ? 
                            <IxIcon name="chevron-up" size="16" />
                            :
                            <IxIcon name="chevron-down" size="16"/>
                        }
                    </>
                    }
                    
                </div>
            </div>
        </th>
    )
}

export default HeadingCell;