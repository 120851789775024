import React, { useEffect, useRef } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Micro from "@amcharts/amcharts5/themes/Micro";
import styles from './ProcessChart.module.css';
import { MeasurementData } from 'app/types/internal/Process';
import { useStore } from 'app/state/StoreProvider';
import { observer } from 'mobx-react-lite';

const ChartColorMap = new Map<number, string>([
    [0, "#00af8e"],
    [1, "#00557c"],
    [2, "#8d00d4"],
    [3, "#71758f"],
    [4, "#ca5e77"],
    [5, "#dd886a"],
    [7, "#0097b8"],
    [8, "#504bb6"],
    [9, "#909700"]
]);
    
const getColor = (index: number): string => {
    if (ChartColorMap.has(index)) {
        return ChartColorMap.get(index)!;
    }
    else {
        return "#7c40ff"
    }
}

const getLabelText = (time, value, uom) => {
    if (uom) {
        return `${time}: ${value} ${uom}`
    } else {
        return `${time}: ${value}`
    }

}

interface Props {
    data: MeasurementData[];
}

const ProcessChart = observer(({data}: Props) => {
    const chartRef = useRef<HTMLDivElement>()
    const amChartRootRef = useRef<am5.Root | null>(null);
    const amChartRef = useRef<am5xy.XYChart | null>(null);
    const legendRef = useRef<HTMLDivElement>()
    const amLegendRef = useRef<am5.Legend | null>(null);
    const amLegendRootRef = useRef<am5.Root | null>(null);
    am5.addLicense("AM5C277824327");

    

    const {
        uiState: {
            darkMode
        }
    } = useStore()

    const getThemes = (root: am5.Root): am5.Theme[] => {
        let themes:am5.Theme[] = [];
        const responsive = am5themes_Responsive.new(root);
        responsive.addRule({
            name: "AxisRendererY",
            relevant: function(width, height) {
                return width < am5themes_Responsive.M;
            },
            settings: {
                width: 600,  // Decrease the width to make the chart smaller
                height: 300  
            }
        });
        // themes.push(responsive);
        themes.push(am5themes_Animated.new(root));
        themes.push(am5themes_Responsive.new(root));
        if (darkMode){
            themes.push(am5themes_Dark.new(root));
        }
        return themes;
    }

    useEffect(() => {
        if (!chartRef.current) {
            throw new Error("chartRef.current is not defined");
        }
        let chart_root = am5.Root.new(chartRef.current);
        chart_root._logo?.dispose();
        chart_root.setThemes(getThemes(chart_root));
        chart_root.dateFormatter.setAll({
            dateFormat: "HH:MM:ss",
            dateFields: ["valueX"]
        });
        amChartRootRef.current = chart_root;
        let chart = amChartRootRef.current.container.children.push(
            am5xy.XYChart.new(amChartRootRef.current, {
                layout: amChartRootRef.current.gridLayout
            })
        );
        amChartRef.current = chart;
        chart.plotContainer.events.on("wheel", function(ev) {

            if (ev.originalEvent.ctrlKey) {
              ev.originalEvent.preventDefault();
              chart.set("wheelX", "panX");
              chart.set("wheelY", "zoomX");
            }
            else {
              chart.set("wheelX", "none");
              chart.set("wheelY", "none");
            }
        });
        amChartRef.current.set("cursor", am5xy.XYCursor.new(amChartRootRef.current, {
            behavior: "zoomX"
          }));
        
        if (!legendRef.current) {
            throw new Error("legendRef.current is not defined");
        }
        let legend_root = am5.Root.new(legendRef.current);
        legend_root.setThemes(getThemes(legend_root));
        legend_root.dateFormatter.setAll({
            dateFormat: "HH:MM:ss",
            dateFields: ["valueX"]
        });
        amLegendRootRef.current = legend_root;
        let legend = am5.Legend.new(amLegendRootRef.current, {
            width: am5.percent(100),
            useDefaultMarker: true,
            layout: am5.GridLayout.new(amLegendRootRef.current, {
                fixedWidthGrid: true
            }),
            marginTop: 35,
            marginBottom: 15
        });
    
        amLegendRef.current = legend;
        amLegendRootRef.current.container.children.push(amLegendRef.current)
        amLegendRef.current.events.on("boundschanged", function () {
            legendRef.current!.style.height = amLegendRef.current?.height() + "px";
        });
        return () => { amLegendRootRef.current?.dispose(); amChartRootRef.current?.dispose(); }
    }, [chartRef.current, legendRef.current, darkMode])

    useEffect(() => {
        if (amChartRootRef.current && amChartRef.current) {
            amChartRef.current.series.clear()
            amChartRef.current.yAxes.clear()
            amChartRef.current.xAxes.clear()

            let yAxis = amChartRef.current.yAxes.push(
                am5xy.ValueAxis.new(amChartRootRef.current, {
                    numberFormat: "#.00",
                    extraMax: 0.1,
                    extraMin: 0.05,
                    renderer: am5xy.AxisRendererY.new(amChartRootRef.current, {})
                })
            );
            let xAxis = amChartRef.current.xAxes.push(
                am5xy.DateAxis.new(amChartRootRef.current, {
                    baseInterval: { timeUnit: "second", count: 1 },
                    extraMax: 0.05,
                    extraMin: 0.05,
                    renderer: am5xy.AxisRendererX.new(amChartRootRef.current, {})
                })
            );
            data.forEach((selected_data, index) => {
                if (selected_data){
                    if (amChartRootRef.current && amChartRef.current) {
                        let md: MeasurementData = selected_data
                        let series = amChartRef.current.series.push(
                            am5xy.LineSeries.new(amChartRootRef.current, {
                                name:  selected_data.tagName,
                                xAxis: xAxis,
                                yAxis: yAxis,
                                valueYField: "value",
                                valueXField: "date",
                                fill: am5.color(getColor(index)),
                                stroke: am5.color(getColor(index)),
                                legendRangeLabelText: "{name}",
                                tooltip: am5.Tooltip.new(amChartRootRef.current, {
                                    labelText: getLabelText('{valueX}', '{valueY}', md.uom),
                                    pointerOrientation: "horizontal",
                                })
                            })
                        );
                        series.strokes.template.setAll({
                            strokeWidth: 3,
                        });
                        series.fills.template.setAll({
                            fillOpacity: 0.5,
                            visible: false
                        });
                        let data = md.timeSeries.map(ts => {
                            var datetime = ts.time;
                            //Remove Timezone flag
                            datetime =  datetime.replace("Z", "");
                         return { date: new Date(datetime).getTime(), value: ts.value } 
                        })
                        series.data.setAll(data);
                    }
                }
            });
            
            // Update legend
            if (amLegendRef.current) {
                amLegendRef.current.data.setAll(amChartRef.current.series.values);
            }
        }
    }, [data, amChartRootRef.current, amChartRef.current, amLegendRef.current, darkMode])
            
    return (
        <div className={styles.amchart_container}>
            <div ref={chartRef} className={styles.amchart_chart}/>
            <div ref={legendRef} className={styles.amchart_legend}/>
        </div>
    )
});

export default ProcessChart;