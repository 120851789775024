import React, { useState } from 'react';
import styles from "./AuthContainer.module.css";
import { useStore } from 'app/state/StoreProvider';
import classNames from 'classnames/bind';
import { observer } from 'mobx-react-lite';

const cx = classNames.bind(styles);

const AuthContainer = observer(() => {

    const [username, setUsername] = useState(""); // State for the username input
    const [password, setPassword] = useState(""); // State for the password input

    
    const {
        authStore: {
            login,
            logout,
            loggedIn
        },
        uiState: {
            closeAuthWidget
        }
    } = useStore();
    
    const handleLogin = () => {
        login(username, password);
        closeAuthWidget();
    }

    const handleLogout = () => {
        logout(); 
        closeAuthWidget();
    }
   
    return (
        <div className={styles.container}>
            <div className={styles.dialog_container}>
                <div className={styles.top}>
                    {loggedIn ? 
                        <p className={styles.headline_text}>Logout</p>
                        :
                        <p className={styles.headline_text}>Login</p>
                    }
                </div>
                <div className={styles.center}>
                    {loggedIn ? 
                        <div className={styles.logout_container}>
                            <p className={styles.bold_text}> Do you really want to logout?</p>
                            <p className={styles.bread_text}> You will only be able to see demo data when not logged in.</p>
                        </div>
                        :
                        <div className={styles.credentials_container}>
                            <div className={styles.field_container}>
                                <div className={styles.field_title}>Username</div>
                                <input
                                    className={styles.field_input}
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)} // Update username state on input change
                                />
                            </div>
                            <div className={styles.field_container}>
                                <div className={styles.field_title}>Password</div>
                                <input
                                    className={styles.field_input}
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)} // Update password state on input change
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={styles.bottom}>
                    <button 
                        className={cx({
                            button: true,
                            cancel_button: true
                        })}
                        onClick={closeAuthWidget}
                    >
                        Cancel
                    </button>
                    {loggedIn ?
                        <button 
                            className={cx({
                                button: true,
                                action_button: true
                            })}
                            onClick={handleLogout}
                        >
                            Logout
                        </button>
                        :
                        <button 
                            className={cx({
                                button: true,
                                action_button: true
                            })}
                            onClick={handleLogin}
                        >
                            Login
                        </button>
                    }
                </div>
            </div>
        </div>
    )
});

export default AuthContainer;




