import { IxIcon, IxIconButton, IxInputGroup } from '@siemens/ix-react';
import React, { useEffect, useState } from 'react';
import styles from './SearchBar.module.css'
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

interface Props {
    onSearch?: (term:string) => any,
    set_content?: string | null | number,
    placeholder?: string,
    title?: string,
}

const default_search_prop = (term: string) => {
    console.log(term);
  }

const SearchBar = ({onSearch=default_search_prop, set_content, placeholder="Search...", title="Title"}:Props) => {

    const [content, setContent] = useState<string>(set_content ? set_content.toString():"");

    useEffect(()=>{
        setContent(set_content ? set_content.toString():"");
    },[set_content])
 
    const onChangeUpdateContent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter"){
            event.preventDefault();
            onSearch(content);
        }
    }

    return (
        <div className={styles.searchbar_container}>
            <form className={styles.searchbar}>
                <IxInputGroup>
                    <span slot="input-start">
                       <IxIcon name="search" size="16"></IxIcon>
                    </span>
                    <input
                        id="input-string"
                        type="string"
                        onChange={onChangeUpdateContent}
                        onKeyDown={(e) => onKeyDown(e)}
                        value={content}
                        placeholder={placeholder}
                    />
                </IxInputGroup>
            </form>
        </div>
        
    )
}

export default SearchBar;
