import styles from './TraceabilityFilter.module.css';

interface Props {
    onCheck: (filter: 0 | 1 | 2) => any;
    state: 0 | 1 | 2;
}

const TraceabilityFilter = ({onCheck, state}:Props) => {
    
    return(
        <div className={styles.table_filter}>
            <div className={styles.alignment}>
                <input 
                id="checkbox_1"
                onClick={() => onCheck(0)} 
                type="radio" 
                name="filter" 
                checked={state === 0}
                /> 
                <label
                htmlFor="checkbox_1"
                >All</label>
            </div>
            <div className={styles.alignment}>
                <input
                id="checkbox_2" 
                onChange={() => onCheck(1)} 
                type="radio" 
                name="filter" 
                checked={state === 1}
                /> 
                <label
                htmlFor="checkbox_2"
                >Ancestors</label>
            </div>
            <div className={styles.alignment}>
                <input 
                id="checkbox_3"
                onClick={() => onCheck(2)} 
                type="radio" 
                name="filter" 
                checked={state === 2}
                /> 
                <label
                htmlFor="checkbox_3"
                >Descendants</label>
            </div>
        </div>
    )
}

export default TraceabilityFilter;