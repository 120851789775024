import React from "react";
import RootStore from "app/state/RootStore";

let rootStore: RootStore;
const storeContext = React.createContext<RootStore | undefined>(undefined)

export const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  const root = rootStore ?? new RootStore();
  return (
    <storeContext.Provider value={root}>
      {children}
    </storeContext.Provider>
  );
};

export const useStore = () => {
  const context = React.useContext(storeContext)
  if (!context) {
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return context
}