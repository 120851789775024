import { makeAutoObservable } from "mobx";
import RootStore from "./RootStore";

class UIState {
    displayAuth: boolean;

    darkMode: boolean;
    rootStore: RootStore;
    constructor(rootStore: RootStore){
        this.displayAuth = false;
        this.rootStore = rootStore;
        this.darkMode = document.getElementById('body').className === "theme-brand-dark";
        makeAutoObservable(this);
    }

    openAuthWidget = () => {
        this.displayAuth = true;
    }

    closeAuthWidget = () => {
        this.displayAuth = false;
    }

    changeTheme = () => {
        this.darkMode = document.getElementById('body').className === "theme-brand-dark";
    }
}

export default UIState;