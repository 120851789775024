import RootStore from "app/state/RootStore";
import { autorun, makeAutoObservable, observable, ObservableMap, runInAction } from "mobx";
import { MeasurementData, MeasurementName, ProcessData } from "app/types/internal/Process";
import IProcessController from "app/controllers/interfaces/IProcessController";
import DemoProcessController from "app/controllers/demo/ProcessController";
import APIProcessController from "app/controllers/api/ProcessController";

class ProcessStore {
    selectedMeasurementsMap: ObservableMap<string, MeasurementName[]>; //{barcode: measurementName[]}
    measurementDataMap: ObservableMap<string, Map<string, ProcessData>>; //{barcode: {display_name: {name, data}}}
    processController: IProcessController;

    rootStore: RootStore;
    constructor(rootStore: RootStore){
        this.rootStore = rootStore;
        this.selectedMeasurementsMap = observable.map({});
        this.measurementDataMap = observable.map({});
        this.processController = DemoProcessController;

        autorun(() => {
            if(this.rootStore.authStore.loggedIn){
                this.processController = APIProcessController;
            } else {
                this.processController = DemoProcessController;
            }
        });

        autorun(() => {
            if(this.rootStore.materialStore.rootMaterial){
                this.unassignProcessData();
                this.initProcessData(this.rootStore.materialStore.rootMaterial.barcode_id);
            }
        });

        autorun(() => {
            if(this.rootStore.materialStore.selectedMaterial){
                if(this.measurementDataMap.has(this.rootStore.materialStore.selectedMaterial.barcode_id)){
                    return;
                }
                this.initProcessData(this.rootStore.materialStore.selectedMaterial.barcode_id);
            }
        })

        makeAutoObservable(this);
    }

    get selectedMeasurementNames(): MeasurementName[] {
        let material = this.rootStore.materialStore.selectedMaterial;
        if(material){
            let measurementNames = this.selectedMeasurementsMap.get(material.barcode_id);
            if(measurementNames){
                return measurementNames;
            }
        }
        return [];
    }

    get selectedMeasurementData(): MeasurementData[] {
        let material = this.rootStore.materialStore.selectedMaterial;
        if(material){
            let measurementNames = this.selectedMeasurementsMap.get(material.barcode_id);
            if(measurementNames){
                let processDataMap = this.measurementDataMap.get(material.barcode_id);
                let selectedMeasurementData: MeasurementData[] = []
                measurementNames.forEach(name => {
                    if (processDataMap.has(name.tagName)){
                        let selectedData = processDataMap.get(name.tagName).measurementData;
                        if (selectedData){
                            selectedMeasurementData.push(selectedData);
                        } else {
                            this.assignMeasurementData(material.barcode_id, name);
                        }
                    }
                })
                return selectedMeasurementData;
            }
        }
        return [];
    }

    get measurementNames(): MeasurementName[] {
        let material = this.rootStore.materialStore.selectedMaterial;
        if(material){
            let processDataMap = this.measurementDataMap.get(material.barcode_id);
            if (processDataMap){
                let measurementNames: MeasurementName[] = []
                for (const [k,v] of processDataMap.entries()){
                    measurementNames.push(v.measurementName);
                }
                return measurementNames;
            }
        }
        return [];
    }

    setSelectedMeasurements = (barcode_id: string, measurements: MeasurementName[]) => {
        this.selectedMeasurementsMap.set(barcode_id, measurements);
    }

    initProcessData = async (barcode_id: string) => {
        try {
            let names: MeasurementName[] = await this.processController.getMeasurementNames(barcode_id);
            if (names) {
                let default_measurement = names[1];
                if (default_measurement){
                    runInAction(async () => {
                        // Create new entry for material
                        this.measurementDataMap.set(barcode_id, new Map<string, ProcessData>());
                        // Automatically choose the first measurement to be selected
                        this.selectedMeasurementsMap.set(barcode_id, [default_measurement]);
                        // Set the processData to be empty for all measurements
                        names.forEach(name => {
                            this.measurementDataMap.get(barcode_id).set(name.tagName, {measurementName: name, measurementData: null});
                        });
                        let data: MeasurementData = await this.processController.getMeasurementData(barcode_id, default_measurement);
                        if (data) {
                            runInAction(() => {
                                this.measurementDataMap.get(barcode_id).set(default_measurement.tagName, {measurementName: default_measurement, measurementData: data});
                            });
                        };
                    });
                }
            }
        } catch (e) {
            console.log(e);
        }
    }

    assignMeasurementData = async (barcode_id: string, measurement: MeasurementName) => {
        try {
            let data: MeasurementData = await this.processController.getMeasurementData(barcode_id, measurement);
            if (data) {
                runInAction(() => {
                    this.measurementDataMap.get(barcode_id).set(measurement.tagName, {measurementName: measurement, measurementData: data});
                });
            };
        } catch (e) {
            console.log(e);
        }
    }

    unassignProcessData = () => {
        this.selectedMeasurementsMap.clear();
        this.measurementDataMap.clear();
    }
}

export default ProcessStore