import { IxIcon } from "@siemens/ix-react";
import classNames from "classnames/bind";
import { useRef, useState, useEffect } from "react";
import styles from './Dropdown.module.css'

let cx = classNames.bind(styles)

interface Props {
    label?: string;
    stickLeft?: boolean;
    children: React.ReactNode;
}

const Dropdown = ({label="Label", stickLeft=false, children}:Props) => {

    const [displayDropdown, setDisplayDropdown] = useState<boolean>(false)

    const outside_click_ref = useRef<HTMLDivElement>(null);
    const label_click_ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickInside(event: any) {
            if (outside_click_ref.current && !outside_click_ref.current.contains(event.target) && !label_click_ref.current?.contains(event.target)) {
                setDisplayDropdown(false)
              }
        }
        // Bind the event listener
        document.addEventListener("mouseup", handleClickInside);
        return () => {
          // Unbind the event listener on clean up
          document.removeEventListener("mouseup", handleClickInside)
        };
      }, [outside_click_ref]);
      
    return (
        <div className={styles.dropdown_container}>
            <div ref={label_click_ref} className={styles.label} onClick={() => setDisplayDropdown(p => !p)}>
                <IxIcon name="monitorings" size="24" />
                <span className={styles.label_text}>{label}</span>
                { displayDropdown ?
                    <IxIcon name="chevron-up-small" size="24" />
                    :
                    <IxIcon name="chevron-down-small" size="24"/>
                }
            </div>
            { displayDropdown &&
                <div 
                    ref={outside_click_ref} 
                    className={cx({
                        dropdown_menu: true,
                        stick_left: stickLeft
                    })}
                >
                    {children}
                </div>
            }
        </div>
    );
}

export default Dropdown;