import { Material, MaterialSummary } from "app/types/internal/Materials";
import { arg_getRelatedMaterials } from "app/types/internal/Arguments";
import RootStore from "app/state/RootStore";
import { makeAutoObservable, runInAction, toJS, autorun, reaction } from "mobx";
import APIMaterialController from "app/controllers/api/MaterialController";
import DemoMaterialController from "app/controllers/demo/MaterialController";
import IMaterialController from "app/controllers/interfaces/IMaterialController";
import { toast } from "react-toastify";

class MaterialStore {
    rootMaterial: Material | null;
    selectedMaterialStack: Material[];
    selectedMaterialIndex: number;
    relatedMaterials: MaterialSummary[] | null;
    precedingMaterials: MaterialSummary[] | null;
    succeedingMaterials: MaterialSummary[] | null;
    traceabilityFilter: 0 | 1 | 2; // 0 = Both, 1 = Backwards, 2 = Forwards.
    searchFilter: string;
    fieldSort: keyof MaterialSummary;
    directionSort: 1 | -1; //  1 = descending, -1 = ascending
    typeFilter: string; // material_name or "" if no filter
    materialController: IMaterialController | null;

    rootStore: RootStore;
    constructor(rootStore: RootStore){
        this.rootStore = rootStore;
        this.rootMaterial = null;
        this.selectedMaterialStack = [];
        this.selectedMaterialIndex = -1;
        this.relatedMaterials = null;
        this.precedingMaterials = null;
        this.succeedingMaterials = null;
        this.traceabilityFilter = 0; 
        this.searchFilter = "";
        this.fieldSort = "material_name";
        this.directionSort = 1;
        this.typeFilter = "";
        this.materialController = DemoMaterialController;

        autorun(() => {
            if(this.rootStore.authStore.loggedIn){
                this.materialController = APIMaterialController;
            } else {
                this.materialController = DemoMaterialController;
            }
        })
        makeAutoObservable(this);
    }

    get allMaterials():MaterialSummary[] | null {
        return this.relatedMaterials;
    }

    get tableMaterials(): MaterialSummary[] | null {
        let filterTable: MaterialSummary[] = [];
        if (this.relatedMaterials == null) {
            return null;
        }
        if(this.traceabilityFilter === 0){
            filterTable = this.relatedMaterials;
        }else if(this.traceabilityFilter === 1){
            filterTable = this.precedingMaterials;
        }else if(this.traceabilityFilter === 2){
            filterTable = this.succeedingMaterials;
        }

        let filter_by_search = filterTable.filter((m: MaterialSummary) => {
            if (this.searchFilter === ""){
                return true;
            }
            let matches_barcode = m.barcode_id?.toLowerCase().includes(this.searchFilter.toLowerCase());
            let matches_name = m.material_name?.toLowerCase().includes(this.searchFilter.toLowerCase());
            let matches_material_id = m.material_id?.toString().toLowerCase().includes(this.searchFilter.toLowerCase());
            let matches_workorder = m.workorder_id?.toString().toLowerCase().includes(this.searchFilter.toLowerCase());
            return matches_barcode || matches_name || matches_material_id || matches_workorder;
        })

        let filter_by_type = filter_by_search.filter((m: MaterialSummary) => {
            if (this.typeFilter === ""){
                return true;
            }
            return this.typeFilter === m.material_name
        })

        let sortedTable = filter_by_type.sort((a,b) => {
            let comparison = 0;
            if (a[this.fieldSort] > b[this.fieldSort]){
                comparison = 1;
            }
            if (a[this.fieldSort] < b[this.fieldSort]){
                comparison = -1;
            }
            return comparison*this.directionSort;
        })

        return sortedTable;
    }

    get selectedMaterial(): Material{
        return this.selectedMaterialStack[this.selectedMaterialIndex];
    }

    assignSelectedMaterialIndex = (index: number) => {
        if (index > -1 && index < this.selectedMaterialStack.length){
            this.selectedMaterialIndex = index;
        }
    }

    assignRootMaterial = async (barcode_id: string) => {
        try {
            const material = await this.materialController.getMaterialDetails(barcode_id);
            if (material !== null) {
                runInAction(() => {
                    this.rootMaterial = material;
                });
            } else {            
                runInAction(() => {
                    this.rootMaterial = this.createEmptyMaterial();
                    toast.warning(`Material ${barcode_id} does not exist.` , {
                        position: "bottom-right",
                        closeOnClick: true,
                    });
                });
            }
        } catch(e) {
            console.log("Error getting material: " + e);
        }
    }

    assignSelectedMaterial = async (barcode_id: string) => {
        let material = await this.materialController.getMaterialDetails(barcode_id);
        if (material !== null){
            runInAction(() => {
                //Remove dangling materials at end of stack
                if (this.selectedMaterialIndex !== this.selectedMaterialStack.length-1){
                    if (this.selectedMaterialStack.length > 1){
                        this.selectedMaterialStack = this.selectedMaterialStack.slice(0, this.selectedMaterialIndex+1)
                    }
                }
                // If material is selected and its the same as the current one, dont add it to stack
                if (this.selectedMaterialStack.length > 0){
                    // trick to compare mobx observables to objects ¯\_(ツ)_/¯
                    if (JSON.stringify(toJS(this.selectedMaterial)) !== JSON.stringify(material)){
                        this.selectedMaterialStack.push(material);
                        this.selectedMaterialIndex = this.selectedMaterialStack.length-1;
                    }
                }
                if (this.selectedMaterialStack.length === 0){
                    this.selectedMaterialStack.push(material);
                    this.selectedMaterialIndex = this.selectedMaterialStack.length-1;
                }
            });
        } else {
            runInAction(() => {
                this.selectedMaterialStack.push(this.createEmptyMaterial());
                this.selectedMaterialIndex = this.selectedMaterialStack.length-1;
                toast.warning(`Material ${barcode_id} does not exist.` , {
                    position: "bottom-right",
                    closeOnClick: true,
                });
            })
        }
    }

    assignRelatedMaterials = async (barcode_id: string) => {
        try {
            let p_args: arg_getRelatedMaterials = {
                barcode_id: barcode_id,
                traceabilityFilter: 1, 
            } 
            let s_args: arg_getRelatedMaterials = {
                barcode_id: barcode_id,
                traceabilityFilter: 2, 
            } 
            const precedingMaterials = await this.materialController.getRelatedMaterials(p_args);
            const succeedingMaterials = await this.materialController.getRelatedMaterials(s_args);
            runInAction(() => {
                this.precedingMaterials = precedingMaterials;
                this.succeedingMaterials = succeedingMaterials;
                this.relatedMaterials = this.precedingMaterials.concat(this.succeedingMaterials);
            })
        } catch(e) {
            console.log(e);
            runInAction(() => {
                this.relatedMaterials = null;
                this.precedingMaterials = null;
                this.succeedingMaterials = null;
            })
        }
    }

    assignSortDirection = (direction: 1 | -1) => {
        this.directionSort = direction;
    }

    assignSortField = (k: keyof MaterialSummary) => {
        this.fieldSort = k;
    }

    unassignRootMaterial = () => {
        this.rootMaterial = null;
    }

    unassignSelectedMaterial = () => {
        this.selectedMaterialStack = [];
        this.selectedMaterialIndex = -1;
    }
    
    unassignRelatedMaterials = () => {
        this.relatedMaterials = null;
        this.precedingMaterials = null;
        this.succeedingMaterials = null;
    }

    assignTraceabilityFilter = async (filter: 0 | 1 | 2) => {
        this.traceabilityFilter = filter;
    }

    unassignTraceabilityFilter = () => {
        this.traceabilityFilter = 0;
    }

    assignSearchFilter = (term: string) => {
        this.searchFilter = term;
    }

    unassignSearchFilter = () => {
        this.searchFilter = "";
    }

    assignTypeFilter = (material_id: string) => {
        this.typeFilter = material_id;
    }

    unassignTypeFilter = () => {
        this.typeFilter = "";
    }

    resetFilters = () => {
        this.unassignTypeFilter();
        this.unassignSearchFilter();
        this.unassignTraceabilityFilter();
    }

    private createEmptyMaterial(): Material {
        let material: Material = {
            container_id: null,
            barcode_id: null,
            material_id: null,
            recipe_id: null,
            workorder_id: null,
            batch_id: null,
            carrier_id: null,
            material_name: null,
            registration_datetime_iso: null,
            children: [],
            parents: [],
            equipment_ids: null,
            measurements: [],
            transactions: [],
            quality: [],
        };
        return material;
    }
}

export default MaterialStore;