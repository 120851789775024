import { MaterialSummary } from "app/types/internal/Materials";

const ExportController = (() => {

    const downloadTable = (m_table: MaterialSummary[]) => {
        if (m_table.length > 0){
            const headers: string[] = ["serial number", "material name", "material id", "workorder", "registration date"]
            const values: string[] = m_table.map((m: MaterialSummary) => {
                return [
                    m.barcode_id.toString(),
                    m.material_name.toString(),
                    m.material_id.toString(),
                    m.workorder_id.toString(),
                    m.registration_datetime_iso
                ].join(",")
            })
            let values_string:string = values.join("\n")
            window.open(encodeURI('data:text/csv;charset=utf-8,' + headers + '\n' + values_string));
        }
    }

    return {
        downloadTable,
    }
})();

export default ExportController;