import styles from './MaterialPage.module.css';
import SearchBar from 'app/view/components/searchBar/SearchBar';
import Headline from 'app/view/components/headline/Headline';
import FilterContainer from 'app/view/containers/filterContainer/FilterContainer';
import TableContainer from 'app/view/containers/tableContainer/TableContainer';
import Detail from 'app/view/containers/detailContainer/Detail';
import TableHeadline from 'app/view/components/tableHeadline/TableHeadline';
import { useStore } from 'app/state/StoreProvider';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { redirect, useLoaderData, useSubmit } from 'react-router-dom';

export const action = async (request: Request) => {
    let data = await request.formData()
    return redirect(`/material/${data.get("barcode")}`)
}

const HomePage = observer(() => {

    const [rootSearchTerm, setRootSearchTerm] = useState<string>("");
    const barcodeFromLoader = useLoaderData();
    
    let submit = useSubmit();

    const { 
        materialStore: {
            rootMaterial,
            assignRootMaterial,
            unassignRootMaterial,
            selectedMaterial,
            assignSelectedMaterial,
            unassignSelectedMaterial,
            assignRelatedMaterials,
            unassignRelatedMaterials,
            allMaterials,
            tableMaterials,
            resetFilters,
        },
    } = useStore()

    useEffect(() => {
        unassignRootMaterial();
        unassignSelectedMaterial();
        resetFilters();
        unassignRelatedMaterials();
        let root_container_id = barcodeFromLoader as string;
        console.log(root_container_id)
        if (root_container_id){
            assignRootMaterial(root_container_id);
            assignSelectedMaterial(root_container_id);
            assignRelatedMaterials(root_container_id);
        }
    }, [barcodeFromLoader])

    useEffect(() => {
        if (rootMaterial) {
          if (rootMaterial.barcode_id) {
            setRootSearchTerm(rootMaterial.barcode_id);
          }
        }
      }, [rootMaterial]);

    const onMainSearch = (term: string) => {
        submit({barcode: term}, {method: "post"});
    }

    return (
        <div className={styles.root}>
            <div className={styles.search_container}>
                <SearchBar
                    onSearch={term => onMainSearch(term)}
                    set_content={rootSearchTerm}
                    placeholder="Serial number"
                    title="Serial number"
                />
                <Headline
                    material={rootMaterial}
                    onClick={() => {
                        if (rootMaterial){
                            if (rootMaterial.barcode_id){
                                assignSelectedMaterial(rootMaterial.barcode_id)
                            }
                        }}
                    }
                />
            </div>
            <div className={styles.table_container}>
                <div className={styles.horizontal}>
                    <TableHeadline total_count={allMaterials ? allMaterials.length : 0} show_count={tableMaterials ? tableMaterials.length : 0}/>
                    <FilterContainer/>
                </div>

                <TableContainer />
            </div>
            <div className={styles.detail_container}>
                <Detail material={selectedMaterial ? selectedMaterial : null}/>
            </div>
        </div>
    )
});

export default HomePage;