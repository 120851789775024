import styles from './TableHeadline.module.css';

interface Props {
    total_count: number;
    show_count: number
}
const TableHeadline = ({total_count, show_count}:Props) => {
    return (
        <div className={styles.headline_container}>
            <p className={styles.title}>Related Materials</p>
            <p className={styles.subtitle}>Showing {show_count} out of {total_count}</p>
        </div>
    )
}

export default TableHeadline;

