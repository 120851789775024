import React from 'react';
import styles from './MeasurementInfo.module.css';
import { MeasurementData, MeasurementName } from 'app/types/internal/Process';

interface Props {
    measurementName: MeasurementName;
    measurementData: MeasurementData;
}

const MeasurementInfo = ({measurementName, measurementData}: Props) => {

    const cleanTime = (dateTime: Date): string => {
        var locale = 'NO'
        var date_string = dateTime.toDateString();
        var time_string = dateTime.toLocaleTimeString(locale=locale);
        return date_string + " " + time_string + " " + `(${locale})`;
    }

    return (
        <div>
            <div className={styles.info_title}> {measurementName.resourceName + ' : '+ measurementName.tagName} </div>
            <div className={styles.info_values}>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Tag name, start </p>
                    <p className={styles.content_element_property_value}>{measurementData.transactionStart.name}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Time, start </p>
                    <p className={styles.content_element_property_value}>{cleanTime(new Date(measurementData.transactionStart.time))}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Tag name, stop </p>
                    <p className={styles.content_element_property_value}>{measurementData.transactionEnd.name}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Time, stop </p>
                    <p className={styles.content_element_property_value}>{cleanTime(new Date(measurementData.transactionEnd.time))}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Unit of measurement </p>
                    <p className={styles.content_element_property_value}>{measurementData.uom}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Asset ID </p>
                    <p className={styles.content_element_property_value}>{measurementName.assetId}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Resource name </p>
                    <p className={styles.content_element_property_value}>{measurementName.resourceName}</p>
                </div>
                <div className={styles.content_element_properties_row}>
                    <p className={styles.content_element_property_key}>Resource id</p>
                    <p className={styles.content_element_property_value}>{measurementName.resourceId}</p>
                </div>
            </div>
        </div>
    );
}

export default MeasurementInfo;