import classNames from 'classnames/bind';
import { observer } from 'mobx-react-lite';
import { useStore } from 'app/state/StoreProvider';
import { MaterialSummary } from 'app/types/internal/Materials';
import HeadingCell from 'app/view/components/tableCells/HeadingCell';
import NameCell from 'app/view/components/tableCells/NameCell';
import TableCell from 'app/view/components/tableCells/TableCell';
import styles from './TableContainer.module.css';
import { IxSpinner } from '@siemens/ix-react';

const cx = classNames.bind(styles)

const TableContainer = observer(() =>{

    const { 
        materialStore: {
            tableMaterials,
            fieldSort,
            assignSortField,
            directionSort,
            assignSortDirection,
            assignSelectedMaterial,
            selectedMaterial,
        },
    } = useStore()

    const getSortDirection = (): "asc" | "desc" => {
        if (directionSort === 1){
            return "desc";
        }
        return "asc";
    }

    const handleFieldSort = (key: keyof MaterialSummary) => {
        if (fieldSort !== key){
            assignSortField(key);
            assignSortDirection(1);
        }
        if (fieldSort === key){
            if (directionSort === 1){
                assignSortDirection(-1);
            }
            if (directionSort === -1){
                assignSortDirection(1);
            }
        }
    }

    const onSelectMaterial = (m: MaterialSummary) => {
        assignSelectedMaterial(m.barcode_id); 
    }

    return(
        <div className={styles.table_wrapper}>
            <table className={styles.table_container}>
                <thead className={styles.table_header_section}>
                    <tr className={styles.table_header_row}>
                        <HeadingCell 
                            title={'Material'}
                            onClick={() => handleFieldSort("material_name")}
                            showSortDirection={fieldSort === "material_name"}
                            sortDirection={getSortDirection()}                    
                        />
                        <HeadingCell 
                            title={'Serial Number'}
                            onClick={() => handleFieldSort("barcode_id")} 
                            showSortDirection={fieldSort === "barcode_id"}
                            sortDirection={getSortDirection()}                    
                        />
                        <HeadingCell 
                            title={'Workorder'}
                            onClick={() => handleFieldSort("workorder_id")} 
                            showSortDirection={fieldSort === "workorder_id"}
                            sortDirection={getSortDirection()}                    
                        />
                        <HeadingCell 
                            title={'Registration Date'}
                            onClick={() => handleFieldSort("registration_datetime_iso")} 
                            showSortDirection={fieldSort === "registration_datetime_iso"}
                            sortDirection={getSortDirection()}                    
                        />
                    </tr>
                </thead>
                { tableMaterials &&
                    <tbody className={styles.table_body_section}>
                        {tableMaterials.map((m:MaterialSummary) => { 
                            let date = new Date(m.registration_datetime_iso)
                            return (
                            <tr className={cx({
                                table_row: true,
                                table_row_selected_material: m.barcode_id === selectedMaterial?.barcode_id,
                            })}>
                                <NameCell material={m} onClickName={() => onSelectMaterial(m)}/>
                                <TableCell content={m.barcode_id ? m.barcode_id : ""} />
                                <TableCell content={m.workorder_id ? m.workorder_id.toString() : ""} />
                                <TableCell content={date.toUTCString().slice(4, 16)} />
                            </tr>
                        )})}
                    </tbody>
                }
            </table>
            { !tableMaterials &&
                <div className={styles.spinner_container}>
                    <IxSpinner size="large"></IxSpinner>
                </div>
            }
        </div>
        
    )
})

export default TableContainer;