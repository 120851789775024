import React from "react";
import styles from './ProcessTab.module.css';
import { useStore } from "app/state/StoreProvider";
import { observer } from "mobx-react-lite";
import Dropdown from "app/view/components/dropdown/Dropdown";
import DropItem from "app/view/components/dropdown/DropItem";
import DropItemCheckBox from "app/view/components/dropdown/DropItemCheckBox";
import { MeasurementName } from "app/types/internal/Process";
import MeasurementInfo from "app/view/components/measurementInfo/MeasurementInfo";
import ProcessChart from "app/view/components/processChart/ProcessChart";
import { IxEmptyState, IxSpinner } from "@siemens/ix-react";

const ProcessTab = observer(() => {

  const {
    materialStore: {
      selectedMaterial,
    },
    processStore: {
      selectedMeasurementNames,
      selectedMeasurementData,
      measurementNames,
      setSelectedMeasurements,
    }
  } = useStore();

  const getDropdownLable = (): string => {
    if (selectedMeasurementNames.length > 1) {
      return `${selectedMeasurementNames[0].resourceName +' : '+ selectedMeasurementNames[0].tagName} +${selectedMeasurementNames.length - 1}`;
    }
    if (selectedMeasurementNames.length === 1) {
      return selectedMeasurementNames[0].resourceName +' : '+ selectedMeasurementNames[0].tagName;
    }
    return "Process variable";
  }

  const onClear = () => {
    setSelectedMeasurements(selectedMaterial.barcode_id, []);
  }

  const isSelected = (t: MeasurementName): boolean => {
    if (selectedMeasurementNames.find(name => name.tagName === t.tagName)){
      return true;
    }
    return false;
  }

  const onSelect = (t: MeasurementName) => {
    if(selectedMeasurementNames.find(name => name.tagName === t.tagName)){
      setSelectedMeasurements(selectedMaterial.barcode_id, selectedMeasurementNames.filter(tag => tag.tagName !== t.tagName));
    } else {
      setSelectedMeasurements(selectedMaterial.barcode_id, [...selectedMeasurementNames, t])
    }
  }

  return (
    <>
    {selectedMaterial &&
      <div className={styles.process_tab_container}>
          {measurementNames.length > 0 &&
            <div className={styles.tag_selection_container}>
              <Dropdown label={getDropdownLable()} stickLeft={true}>
                <DropItem
                  text={"Clear"}
                  selected={selectedMeasurementNames.length === 0}
                  onClick={onClear}
                />
                {measurementNames.map(t =>
                  <DropItemCheckBox
                    text={t.tagName}
                    selected={isSelected(t)}
                    onClick={() => onSelect(t)}
                  />
                )}
              </Dropdown>
            </div>
          }
          {selectedMeasurementNames.length > 0 ?
            <ProcessChart data={selectedMeasurementData}/>
            :
            <IxEmptyState
            header={measurementNames.length > 0 ? "No selected data" : "No data available"}
            subHeader={measurementNames.length > 0 ? "Select data first" : ""}
            icon={measurementNames.length > 0 ? "add" : "info"}
            onActionClick={console.log}
            >
            </IxEmptyState>
          }
          { selectedMeasurementData.map(tag => {
              let name = selectedMeasurementNames.find(n => n.tagName == tag.tagName);
              if (name) {
                return <MeasurementInfo measurementName={name} measurementData={tag}/>
              } else {
                return <MeasurementInfo measurementName={{tagName: tag.tagName, resourceName: tag.tagName, assetId: tag.tagName, resourceId: tag.tagName}} measurementData={tag}/>
              }
            })
          }
      </div>
        }
        {!selectedMaterial &&
          <div className={styles.spinner_container}>
            <IxSpinner></IxSpinner>
          </div>
        }
    </>
  )
})

export default ProcessTab