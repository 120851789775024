import { useState, useEffect } from 'react';
import styles from './QualityTab.module.css';
import Accordion from 'app/view/components/accordion/Accordion';
import { useStore } from "app/state/StoreProvider";
import { Material } from 'app/types/internal/Materials';
import { IxSpinner } from '@siemens/ix-react';

const QualityTab = () => {

    const {
        materialStore: {
            selectedMaterial
        }
    } = useStore();

    const [qualityData, setQualityData]: any = useState({});

    useEffect(() => {
        
        prepareQualityData(selectedMaterial)
    }, [selectedMaterial])

    function prepareQualityData(material: Material) {
        const materialQuality = material?.quality || false;
        if (!materialQuality) {
            return {
            };
        }
        let preparedQualityData: any = {};
        
        for (let index in materialQuality) {
            if (!(Object.keys(preparedQualityData)?.includes(materialQuality[index].category.toString()))) {
                preparedQualityData[`${materialQuality[index].category}`] = {};
            }
            if (!(Object.keys(preparedQualityData[`${materialQuality[index].category}`])?.includes(materialQuality[index].sub_category))) {
                preparedQualityData[`${materialQuality[index].category}`][`${materialQuality[index].sub_category}`] = [];
            }
            preparedQualityData[`${materialQuality[index].category}`][`${materialQuality[index].sub_category}`].push(materialQuality[index]);
        }
        
        setQualityData(preparedQualityData);
    }
    return (
        <>
        { selectedMaterial ? 
            <Accordion accordionElements={qualityData} styles={styles}/>
        :
            <div className={styles.spinner_container}>
                <IxSpinner></IxSpinner>
            </div>
        }
        </>
    )
}

export default QualityTab;