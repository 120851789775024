import { EquipmentCollection, ProcessTag } from "app/types/API/Process";
import { authHeader } from "app/utils/headers";
import Cookies from 'js-cookie';


const ProcessService = (() => {
    const HOST = process.env.REACT_APP_API_URL;
    
    const getProcess = async (barcode_id: string): Promise<EquipmentCollection> => {
        var request = fetch(`${HOST}/Material/${barcode_id}/Process`, {
            method: "GET",
            mode: "cors",
            headers: {
                 // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            //credentials: 'include',
        })
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<EquipmentCollection>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;
    };

    const getProcessTag = async (barcode_id: string, tagName: string, assetID: string, resourceId: string): Promise<ProcessTag> => {
        var request = fetch(`${HOST}/Material/${barcode_id}/Process/${tagName}/${assetID}/${resourceId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                 // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },
            //credentials: 'include',
        })
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<ProcessTag>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;
    };

    return {
        getProcess,
        getProcessTag
    };
})();

export default ProcessService;