import styles from './TableCells.module.css';
import { MaterialSummary } from "app/types/internal/Materials";

interface Props {
    material: MaterialSummary;
    onClickName: () => void;
}

const NameCell = ({material, onClickName}:Props) => {
    return(
        <td className={styles.table_cell}> 
            <div className={styles.table_cell_material_name}>
                <span className={styles.material_name_text} onClick={onClickName}>
                    {material.material_name ? material.material_name : "N/A"}
                </span> 
                <p className={styles.table_cell_barcode_subtitle}>{material? material.material_id : "N/A"}</p>
            </div>
        </td>
    );
}

export default NameCell;



