import MaterialStore from "app/state/stores/MaterialStore";
import ProcessStore from "app/state/stores/ProcessStore";
import AuthStore from "app/state/stores/AuthStore";
import UIState from "app/state/UIState";

export default class RootStore {
    uiState: UIState;
    authStore: AuthStore;
    materialStore: MaterialStore;
    processStore: ProcessStore;
    constructor() {
        // Order matters due to dependencies!
        this.uiState = new UIState(this);
        this.authStore = new AuthStore(this);
        this.materialStore = new MaterialStore(this);
        this.processStore = new ProcessStore(this);
    }
}


