import styles from './TableCells.module.css';

interface Props {
    content: string | number;
}

const TableCell = ({content}:Props) => {
    
    return(
        <td className={styles.table_cell}> 
            <span>{content? content : "N/A"}</span> 
        </td>
    );
}

export default TableCell;



