import { useState } from 'react';
import styles from './Detail.module.css';
import QualityTab from 'app/view/containers/qualityTab/QualityTab';
import { Material } from "app/types/internal/Materials";
import Headline from 'app/view/components/headline/Headline';
import { IxIcon } from '@siemens/ix-react';
import { useStore } from 'app/state/StoreProvider';
import { observer } from 'mobx-react-lite';
import ProcessTab from 'app/view/containers/processTab/ProcessTab';
import TraceabilityTab from 'app/view/containers/traceabilityTab/TraceabilityTab';
import { useSubmit } from 'react-router-dom';

interface Props {
    material: Material | null;
}

const Detail = observer(({ material }: Props) => {

    let submit = useSubmit();

    const {
        materialStore: {
            selectedMaterialIndex,
            assignSelectedMaterialIndex,
            selectedMaterialStack,
        }
    } = useStore()

    const [activeIndex, setActiveIndex] = useState(0);

    function setTabBarStyling(index: number) {
        return activeIndex !== index ? styles.tabbar_element : `${styles.tabbar_element} ${styles.tabbar_element__selected}`;
    }
    

    function onDownload() {
        if (material) {
            const headers = Object.keys(material);
            const values = Object.values(material);
            window.open(encodeURI('data:text/csv;charset=utf-8,' + headers + '\n' + values));
        }
    }

    const onMainSearch = (term: string) => {
        submit({barcode: term}, {method: "post"});
    }

    const renderTab = () => {
        switch(activeIndex){
            case 0:
                return <TraceabilityTab/>
            case 1:
                return <QualityTab/>
            case 2:
                return <ProcessTab/>
            default:
                return <></>
        }
    }

    return (
        <div className={styles.detail_container}>
            <div className={styles.detail_container_icons}>
                <div className={styles.detail_container_navigation_buttons}>
                    { selectedMaterialIndex > 0 ?
                        <div className={`${styles.detail_container_icon}`} onClick={() => {assignSelectedMaterialIndex(selectedMaterialIndex-1)}}>
                            <IxIcon name="arrow-left" size="24" />
                        </div>
                        :
                        <div className={`${styles.detail_container_icon} ${styles.hidden}`}><IxIcon name="arrow-left" size="24" /></div>
                    }
                    { selectedMaterialIndex+1 < selectedMaterialStack.length ?
                        <div className={`${styles.detail_container_icon}`} onClick={() => {assignSelectedMaterialIndex(selectedMaterialIndex+1)}}>
                            <IxIcon name="arrow-right" size="24" />
                        </div>
                        :
                        <div className={`${styles.detail_container_icon} ${styles.hidden}`}><IxIcon name="arrow-right" size="24" /></div>
                    }
                </div>
                <div className={`${styles.detail_container_icon}`} onClick={() => onDownload()}>
                    <IxIcon name="download" size="24" />
                </div>
            </div>
            <Headline 
                material={material}
                onClick={() => {
                    if (material){
                        onMainSearch(material.barcode_id);
                    }
                }}
                
            />
            <div className={styles.tabbar_container}>
                <div className={setTabBarStyling(0)} onClick={() => setActiveIndex(0)}>Traceability</div>
                <div className={setTabBarStyling(1)} onClick={() => setActiveIndex(1)}>Quality</div>
                <div className={setTabBarStyling(2)} onClick={() => setActiveIndex(2)}>Process</div>
            </div>
            <div className={styles.content_container}>
                {renderTab()}
            </div>
        </div >
    )
});

export default Detail;