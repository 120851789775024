import AccordionItemContent from "./AccordionItemContent";
import { IxIcon } from '@siemens/ix-react';
interface Props {
    elements: any,
    styles: any,
    equipmentKey: string,
    accordionIndex: number,
    clicked: number[],
    setClicked: any
}
const AccordionItem = ({ elements, styles, equipmentKey, accordionIndex, clicked, setClicked }: Props) => {

    const handleToggle = (index: number) => {
        if (clicked.includes(index)) {
            return setClicked(clicked.filter(item => item !== index))
        }
        setClicked((clicked: number[]) => [...clicked, index]);
    };

    return (
        <div>
            <div className={`${styles.content_element_quality_subkey}`}>
                <button className={` ${styles.content_element_quality_header}  ${styles.content_element_quality_button}`} onClick={() => handleToggle(accordionIndex)}>
                    <div className={`${styles.content_element_quality_header_text}`}>
                        {equipmentKey}
                    </div>
                    <span className={styles.selected}>
                        {clicked.includes(accordionIndex) &&
                            <IxIcon name='chevron-up' size="24" />}
                        {!(clicked.includes(accordionIndex)) &&
                            <IxIcon name='chevron-down' size="24" />}
                    </span>
                </button>
                {Object.keys(elements).map((categoryKey: any, index: number) => (
                    <AccordionItemContent key={index} styles={styles} categoryKey={categoryKey} itemContent={elements[categoryKey]} active={clicked.includes(accordionIndex)} />
                ))}
            </div>
        </div>
    );
};

export default AccordionItem;