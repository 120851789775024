import { makeObservable, observable, action, makeAutoObservable } from "mobx";
import RootStore from "app/state/RootStore";
import { toast } from "react-toastify";
import AuthService from "app/services/AuthService";

class AuthStore {
    loggedIn: boolean;

    rootStore: RootStore;
    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
        this.loggedIn = true;

        // const isDevelopment = process.env.NODE_ENV === 'development';
        // if (isDevelopment){
        //this.login("admin", "admin");
        //}
        makeAutoObservable(this);
    }

    login = async (username, password) => {
        // const isDevelopment = process.env.NODE_ENV === 'development';
        // if (isDevelopment){
        //     localStorage.setItem("token", process.env.REACT_APP_BEARERTOKEN);
        //     toast.success("Logged in as developer", {
        //         position: "bottom-right",
        //         closeOnClick: true,
        //     });
        //     this.loggedIn = true;
        // } else {
        
            try{
                var token = await AuthService.getToken(username,password);
            } catch(e){
                console.log("Token Error:",e);
            }
            if(token != null){
                localStorage.setItem("token", token);
                toast.success("Login success!", {
                    position: "bottom-right",
                    closeOnClick: true,
                });
                this.loggedIn = true;
            }
            else{
                toast.error("Wrong user/pass", {
                    position: "bottom-right",
                    closeOnClick: true,
                });
            }
        }
    
    

    logout = () => {
        localStorage.removeItem("token");
        this.loggedIn = false;
        toast.success("Logged out", {
            position: "bottom-right",
            closeOnClick: true
        });
    }
}

export default AuthStore;
