import { Material } from "app/types/API/Material";
import { Quality } from "app/types/API/Quality";
import { arg_getRelatedMaterials } from "app/types/internal/Arguments";
import { arg_getRelatedMaterials as api_getRelatedMaterials } from "app/types/API/Arguments";
import { mapQueryArgsRelatedMaterials } from "app/utils/mappers";
import { authHeader } from "app/utils/headers";
import Cookies from 'js-cookie';



const MaterialService = (() => {
    const HOST = process.env.REACT_APP_API_URL;
    
    const getMaterial = async (barcode_id: string): Promise<Material> => {
        var request = fetch(`${HOST}/Material/${barcode_id}`, {
            method: "GET",
            mode: "cors",
            headers: {
                 // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
                
            },
           // credentials: 'include',
        })
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<Material>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;
    };

    const getRelatedMaterials = async (args: arg_getRelatedMaterials): Promise<Material[]> => {
        let api_args: api_getRelatedMaterials = mapQueryArgsRelatedMaterials(args); 
        var request = fetch(
            `${HOST}/Material/${args.barcode_id}/related?` + new URLSearchParams({...api_args}), {
            method: "GET",
            mode: "cors",
            headers: {
                // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
               
            },
            //credentials: 'include',
    }
        )
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<Material[]>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;
    }

    const getQualityData = async (barcode_id : string) : Promise<Quality[]> => {
        var request = fetch(`${HOST}/Material/${barcode_id}/Quality`, {
            method: "GET",
            mode: "cors",
            headers: {
                 // ...authHeader,
                'Content-Type': 'application/json',
                "X-XSRF-TOKEN": Cookies.get("XSRF-TOKEN"),
            },  
            //credentials: 'include',
        })
        .then((response) => {
            if (!response.ok){
                throw new Error(`Error ${response.status}`);
            }
            return response.json() as Promise<Quality[]>;
        })
        .catch((error) => {
            return Promise.reject(error)
        })
        return request;

    };

    return {
        getMaterial,
        getRelatedMaterials,
        getQualityData
    };
})();

export default MaterialService;