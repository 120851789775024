import classNames from 'classnames/bind';
import styles from './Dropdown.module.css'

interface Props {
    text: string;
    onClick?: () => void;
    selected?: boolean 
}

const consoleLog = () => {
    console.log("clicked")
}

const cx = classNames.bind(styles)

const DropItem = ({text, onClick=consoleLog, selected=false}: Props) => {
    return (
        <div 
            onClick={onClick} 
            className={cx({
                dropdown_item: true,
                dropdown_item_selected: selected,
            })}
        >
            <div className={styles.no_checkbox_padding}></div>
            {text}
        </div>
    )
}

export default DropItem;