import React from 'react'
import {IxBasicNavigation, IxMenu, IxMenuItem} from '@siemens/ix-react';
import {  useLocation, useNavigate} from 'react-router-dom';
import { useStore } from 'app/state/StoreProvider';

const Appbar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        materialStore: {
            selectedMaterial
        },
        uiState: {
            changeTheme
        }
    } = useStore();

    return (
        <IxBasicNavigation hideHeader>
            <IxMenu
            enableToggleTheme
            onClick={() => changeTheme()}
            >
                <IxMenuItem
                    home
                    icon="home"
                    active={location.pathname === "/"}
                    onClick={() => navigate("/")}
                > Home
                </IxMenuItem>
                <IxMenuItem
                    icon="monitor"
                    active={location.pathname.startsWith("/material")}
                    onClick={() => selectedMaterial ? navigate("/material/"+selectedMaterial.barcode_id) : navigate("/material/")}
                > Trace
                </IxMenuItem>
                <IxMenuItem
                    icon="plant"
                    disabled
                > Production
                </IxMenuItem>
                <IxMenuItem
                    icon="trend"
                    disabled
                > Trends
                </IxMenuItem>
            </IxMenu>
        </IxBasicNavigation>
    )
}

export default Appbar;